
import { defineComponent } from 'vue';
import BaseToolbar from '@/components/base/BaseToolbar.vue';
import BaseContent from '@/components/base/BaseContent.vue';
import BaseInput from '@/components/base/BaseInput.vue';
import {
  IonPage,
  IonContent,
  IonHeader,
  IonButton,
  alertController,
} from '@ionic/vue';
import { RouteName } from '@/router/route-const';
import { useRouter } from 'vue-router';
import { useRegister } from '@/hooks/useRegister';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'Login',
  components: {
    BaseToolbar,
    BaseContent,
    BaseInput,
    IonPage,
    IonContent,
    IonHeader,
    IonButton,
  },
  setup() {
    const { t } = useI18n();
    const router = useRouter();
    const { form, disabled, isLoading, errors, submit } = useRegister();

    const confirmRegistration = async () => {
      const alert = await alertController.create({
        header: t('register.confirm-registration-alert-title'),
        message: t('register.confirm-registration-alert-message'),
        buttons: [
          {
            text: t('global.ok'),
            handler: () => {
              router.push({ name: RouteName.LOGIN });
            },
          },
        ],
      });
      await alert.present();
    };

    const register = () => {
      submit().then(() => {
        if (!errors.value) {
          confirmRegistration();
        }
      });
    };

    return {
      form,
      disabled,
      isLoading,

      register,
    };
  },
});
